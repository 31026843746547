import React, { useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import TechnicalSkills from "../TechnicalSkills/TechnicalSkills";
import About from "../About/AboutUs";
import Experience from "../Experience/Experience";
import Certification from "../Certification/Certification";
import Education from "../Education/Education";
import Passions from "../Passions/Passions";
import Footer from "../Footer/Footer";

const Home = () => {
    const contentRef = useRef();
    const [navbarHeight, setNavbarHeight] = useState(0);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };
    return (
        <div className="home" ref={contentRef} style={{ paddingTop: `${navbarHeight}px` }}>
            <Navbar scrollToTop={scrollToTop} navbarHeight={navbarHeight} setNavbarHeight={setNavbarHeight} />
            <Header />
            <About />
            <Experience />
            <TechnicalSkills />
            <Certification />
            <Education />
            <Passions />
            <Footer />
            <button
                onClick={scrollToTop}
                className="scroll-to-top"
                title="Scroll to Top"
            >
                <i className="bi bi-arrow-up"></i>
            </button>
        </div>
    )
}

export default Home;