import React from 'react';
import passionData from '../../data/passionsData.json';

import './passions.css';

const Passions = () => {
    return (
        <div className="common-body-layout passions" id="passions">
            <div className='section-heading'>
                <h2>Passions</h2>
            </div>
            <ul className='passion-ul'>
                {passionData?.data?.map((passion, index) => (
                    <li className='passion-li' key={index} >
                        &#8226;&nbsp;&nbsp;{`${passion}`}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Passions;
