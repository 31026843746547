import React from 'react';
import educationData from '../../data/educationData.json';

import './education.css';

const Education = () => {
  return (
    <div className="common-body-layout education" id="education">
      <div className='section-heading'>
        <h2>Education</h2>
      </div>
      <div className='colleges-details'>
        {educationData?.data?.map((item, index) => (
          <div className='college-detail' key={index}>
            <div className='college-duration'>
              <a href={item?.website} target="_blank" rel="noopener noreferrer">
                <h3>{item.college}</h3>
              </a>
              <strong>{item.start} - {item.end}</strong>
            </div>
            <div className='degree'><h4>{item.degree}</h4></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Education;
