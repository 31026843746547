import React from 'react';

import './footer.css';

const Footer = () => {
    return (
        <div>
            <div className='footer'>
                <h3>{process.env.REACT_APP_PERSON_NAME} © 2024 - All rights reserved</h3>
                <a href={process.env.REACT_APP_URL} target='_blank' rel='noopener noreferrer'>
                    <h5>{process.env.REACT_APP_URL}</h5>
                </a>
            </div>
        </div>
    )
}

export default Footer;
