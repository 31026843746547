import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import experienceData from '../../data/experienceData.json';
import './ExperienceInDetails.css';
import Loading from '../Common/Loading/Loading';
import { FaLink } from "react-icons/fa";
import Footer from '../Footer/Footer';

const ExperienceInDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [experience, setExperience] = useState(null);

    useEffect(() => {
        const foundExperience = experienceData.data.find(item => item.id === id);
        if (foundExperience) {
            setExperience(foundExperience);
        } else {
            navigate('/404');
        }
    }, [id, navigate]);

    if (!experience) {
        return <Loading />;
    }

    return (
        <>
            <div className='common-body-layout experience-details'>
                <button className='back-button' onClick={() => navigate('/')}>
                    &#8592;
                </button>

                <div className='company-header'>
                    <img src={`${process.env.PUBLIC_URL}/companiesLogo/${experience?.logo}`} alt={`${experience?.company} logo`} className='company-logo' />
                    <div>
                        <h1>{experience?.company}</h1>
                        <h2>{experience?.jobTitle}</h2>
                        <p>{experience?.start} - {experience?.end}</p>
                        <a href={experience?.website} target="_blank" rel="noopener noreferrer">Visit Website</a>
                    </div>
                </div>

                <div className='description'>
                    <h3>Description:</h3>
                    <ul>
                        {experience?.description?.map((desc, index) => (
                            <li key={index}>{desc}</li>
                        ))}
                    </ul>
                </div>

                <div className='projects'>
                    <h3>Projects:</h3>
                    {experience?.projects?.map((project, index) => (
                        <div key={index} className='project'>
                            <h4>{project?.name}
                                {project?.link && <a href={project?.link} target="_blank" rel="noopener noreferrer"> <FaLink /></a>}</h4>
                            <h5>Responsibilities: </h5>
                            <ul>
                                {project?.responsibilities?.map((responsibility, i) => (
                                    <li key={i}>{responsibility}</li>
                                ))}
                            </ul>
                            <p><strong>Team Size: </strong> {project?.teamSize}</p>
                            <p><strong>Technologies Used: </strong>
                                {project?.techStack && project?.techStack.join(", ")}
                            </p>
                        </div>
                    ))}
                </div>

                <div className='skillsLearned'>
                    <h3>Skills Learned:</h3>
                    {experience?.skillsLearned && (
                        <ul>
                            {experience?.skillsLearned?.map((skill, index) => (
                                <li key={index}>{skill}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ExperienceInDetails;
