import React from 'react';
import experienceData from '../../data/experienceData.json'
import { useNavigate } from 'react-router-dom';

import './experience.css'

const Experience = () => {
    const navigate = useNavigate();
    return (
        <div className="common-body-layout experience" id="experience">
            <div className='section-heading'>
                <h2>Experience</h2>
            </div>
            <div className="timeline">
                {experienceData?.data?.map((exp, index) => {
                    const direction = index % 2 === 0 ? 'left' : 'right';
                    const animationDelay = `${(index + 1) * 1000}ms`;
                    return (
                        <div key={index} className={`container ${direction}-exp`} style={{ animationDelay }}>
                            <a href={exp.website} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={`${process.env.PUBLIC_URL}/companiesLogo/${exp.logo}`}
                                    alt={exp.logo}
                                />
                            </a>
                            <div className="text-box">
                                <h2>{exp.company}</h2>
                                <h4>{exp.jobTitle}</h4>
                                <small>{exp.start} - {exp.end}</small>
                                <ul>
                                    {exp.description?.map((ele, index) => (
                                        <li key={index}>{ele}</li>
                                    ))}
                                </ul>
                                <strong
                                    onClick={() => {
                                        navigate(`/company/${exp.id}`)
                                    }}>
                                    Projects - See More
                                </strong>
                                <span className={`${direction}-arrow`}></span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Experience;