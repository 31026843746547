import React, { useEffect, useState, useRef } from 'react';
import './carousal.css';

const Carousal = (props) => {
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const prevIndexRef = useRef();

    const startInterval = () => {
        prevIndexRef.current = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % props?.data?.length);
        }, 4000)

    }

    useEffect(() => {
        startInterval()

        return () => clearInterval(prevIndexRef.current);
    }, [props?.data?.length]);

    const handleNext = () => {
        clearInterval(prevIndexRef.current)
        setIndex((prevIndex) => (prevIndex + 1) % props?.data?.length);
        startInterval()
    };

    const handlePrev = () => {
        clearInterval(prevIndexRef.current)
        setIndex((prevIndex) => (prevIndex - 1 + props?.data?.length) % props?.data?.length);
        startInterval()
    };

    const handleDot = (toIndex) => {
        clearInterval(prevIndexRef.current)
        setIndex(toIndex);
        startInterval()
    };

    useEffect(() => {
        setFade(true);
        const timer = setTimeout(() => {
            setFade(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [index]);

    return (
        <div className="carousal">
            <h4 className="course-name">{props.data[index].courseName}</h4>
            <div className={`image-container ${fade ? 'active' : ''}`}>
                <a href={props?.data[index].onSiteCertificateUrl} target="_blank" rel="noopener noreferrer">
                    <img
                        className="image"
                        src={`${process.env.PUBLIC_URL}certificateImg/${props.data[index].certificateUrl}`}
                        alt={props.data[index].courseName}
                    />
                </a>
            </div>
            <button className="prev" onClick={handlePrev}>
                &lt;
            </button>
            <button className="next" onClick={handleNext}>
                &gt;
            </button>
            <div className="dots">
                {props.data.map((_, i) => (
                    <span
                        key={i}
                        className={`dot ${i === index ? 'active' : ''}`}
                        onClick={() => handleDot(i)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Carousal;
