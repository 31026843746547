import React from 'react'
import certificationsData from '../../data/certifications.json';

import './certification.css';
import Carousal from '../Common/Carousal/Carousal';

const Certification = () => {
    return (
        <div className="common-body-layout certifications" id="certifications">
            <div className='section-heading'>
                <h2>Certification</h2>
            </div>
            <div>
                <Carousal data={certificationsData?.data} />
            </div>
        </div>
    )
}

export default Certification;
