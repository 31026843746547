import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import versionData from '../../data/versionData.json';

import './navbar.css';

const Navbar = (props) => {
    const selectRef = useRef(null);
    const [selectedValue, setSelectedValue] = useState(versionData.data[0]?.version || '');


    const updateNavbarHeight = () => {
        const navbar = document.querySelector('.navbar');
        props.setNavbarHeight(navbar.offsetHeight);
    };

    useEffect(() => {
        updateNavbarHeight();
        window.addEventListener('resize', updateNavbarHeight);

        return () => {
            window.removeEventListener('resize', updateNavbarHeight);
        };
    }, []);

    const handleClick = (e) => {
        e.preventDefault()
        if (e?.target?.selectedIndex) {
            const url = versionData.data[e.target.selectedIndex].url
            window.open(url)
            setTimeout(() => {
                setSelectedValue(versionData.data[0]?.version || '')
                selectRef.current.focus()
            }, 100)
        } else {
            const targetId = e.target.getAttribute('href');
            const targetElement = document.querySelector(targetId);
            const navbar = document.querySelector('.navbar');
            const navbarHeight = navbar.offsetHeight;

            if (targetElement) {
                const offsetPosition = targetElement.getBoundingClientRect().top + window.scrollY - navbarHeight;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
                targetElement.classList.add('highlight');
                setTimeout(() => {
                    targetElement.classList.remove('highlight');
                }, 2000);
            }
        }
    }

    const handleDownload = async () => {
        const response = await fetch(`${process.env.PUBLIC_URL}/pdf/cv.pdf`);
        const blob = await response.blob();

        const filename = prompt("Please enter the filename:", `${process.env.REACT_APP_PDF_NAME}`);

        if (filename === null || filename.trim() === "") {
            return;
        }

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className='navbar'>
            <a href='#about' onClick={handleClick}>About Me</a>
            <span className='separator' />
            <a href='#experience' onClick={handleClick}>Experience</a>
            <span className='separator' />
            <a href='#skills' onClick={handleClick}>Technical Skills</a>
            <span className='separator' />
            <a href='#certifications' onClick={handleClick}>Certifications</a>
            <span className='separator' />
            <a href='#education' onClick={handleClick}>Education</a>
            <span className='separator' />
            <a href='#passions' onClick={handleClick}>Passions</a>
            <span className='separator' />
            <i onClick={handleDownload} download className="bi bi-download" />
            <span className='separator' />
            <select className='selector' onChange={handleClick} value={selectedValue} ref={selectRef}>{versionData.data?.map((item, index) => (
                <option key={index}>{item.version}</option>
            ))}</select>
        </div>
    );
}

export default Navbar;