import React from 'react';
import aboutData from '../../data/aboutData.json';

import './about.css';

const About = () => {
    return (
        <div className="common-body-layout about" id="about">
            {aboutData?.data?.map((item, index) => 
                <div key={index}>
                    <p>{item?.paragraph}</p>
                    <br />
                </ div>
            )}
        </div>
    )
}

export default About;