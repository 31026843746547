import React, { useState } from 'react';
import headerData from '../../data/headerData.json';

import './header.css';

const Header = () => {
    // const [zoom, setZoom] = useState(1);

    // const handleMouseEnter = () => setZoom(2);
    // const handleMouseLeave = () => setZoom(1);

    const [copied, setCopied] = useState('');

    const copyDataToClipboard = (type, data) => {
        console.log(type)
        navigator.clipboard.writeText(data).then(() => {
            setCopied(type);
            setTimeout(() => {
                setCopied(false);
            }, 1500);
        });
    };

    return (
        <div className='common-body-layout header' id='header'>
            <div className='details'>
                <h1>{headerData?.data?.name}</h1>
                <h2>{headerData?.data?.position}</h2>
                <div className='contact-details'>
                    <h3>{headerData?.data?.currentLocation}</h3>
                    <div>|</div>
                    <h3 className='copyData' onClick={() => copyDataToClipboard('phone', headerData?.data?.phone)} >
                        {headerData?.data?.phone}
                        {copied === 'phone' && <span className="copied"> Copied!</span>}
                    </h3>
                    <div>|</div>
                    <h3 className='copyData' onClick={() => copyDataToClipboard('email', headerData?.data?.email)} >
                        {headerData?.data?.email}
                        {copied === 'email' && <span className="copied"> Copied!</span>}
                    </h3>
                    <div>|</div>
                    <a href={headerData?.data?.linkedInUrl} target="_blank" rel='noopener noreferrer'>
                        <h3>LinkedIn</h3>
                    </a>
                </div>
            </div>
            <div className='photo'
                // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/logo.jpeg`}
                    alt='pic'
                    // style={{ transform: `scale(${zoom})`, transition: 'transform 0.5s ease' }}
                />
            </div>
        </div>

    )
}

export default Header;

