import React from 'react';
import technicalSkillsData from '../../data/technicalSkills.json';

import './technicalSkills.css';

const TechnicalSkills = () => {
    return (
        <div className="common-body-layout skills" id="skills">
            <div className='section-heading'>
                <h2>Technical Skills</h2>
            </div>
            <ul className='technicalSkills-ul'>
                {Object.entries(technicalSkillsData?.data)?.map(([skillCategory, skills], index) => (
                    <li className='technicalSkill-li' key={index} >
                        <div className='technicalSkill'>
                            <h4>{
                                skillCategory
                                    .replace(/([A-Z])/g, ' $1')
                                    .trim()
                                    .replace(/^./, (str) => str.toUpperCase()) + ': '
                            }</h4><p>{skills.join(', ')}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TechnicalSkills;